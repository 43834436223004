.h-fixed-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all .2s ease-in-out;
  // &.opaque {
  //   @include shadow(0 2px 7px 0 rgba($black,.1));
  // }
}

.header-bg-color {
  background-color: $blue;
  mix-blend-mode: multiply;
  width: rem-calc(202);
  height: rem-calc(116);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  @include breakpoint (medium) {
    width: rem-calc(292);
    height: rem-calc(158);
  }
  @include breakpoint (large) {
    width: rem-calc(438);
    height: rem-calc(223);
  }
}