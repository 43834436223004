.arrow-next {
  background-size: 58px 58px;
  width: rem-calc(58);
  height: rem-calc(58);
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  cursor: pointer;
  > div {
    mix-blend-mode: multiply;
  }
  span {
    background: url('../images/ArrowNext-2.svg') no-repeat center center;
    background-size: 58px 58px;
    width: rem-calc(58);
    height: rem-calc(58);
    position: absolute;
    left: 0;
    top: 0;
  }
}
.slick-prev { display: none !important; }

.slick-list {
  margin: 0 -5px !important;
  @include breakpoint(medium) {
    margin: 0 -13px !important;
  }
}

.slick-slide > div {
  padding: 0 5px !important;
  @include breakpoint(medium) {
    padding: 0 13px !important;
  }
}
