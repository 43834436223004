.news-teaser {
  display: grid;
  grid-gap: 10px;
  @include breakpoint(medium) {
    grid-gap: 26px;
  }
  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .img-text-box {
    display: flex;
    direction: ltr;
    flex-flow: column;
    flex-direction: column;
    .text-box {
      h3,p {
        width: 100%;
      }
      h3 {
        text-transform: uppercase;
      }
    }

    &:nth-child(1) {
      .text-box {
        .button:hover {
          color: $blue;
        }
        &::before {
          background-color: $blue;
        }
      }
    }
    &:nth-child(2) {
      @include breakpoint(large) {
        flex-direction: column-reverse;
      }
      .text-box {
        .button:hover {
          color: $yellow;
        }
        @include breakpoint(large) {
          margin-top: 0;
          margin-bottom: -5.5em;
          padding-top: 3em;
          padding-bottom: 6.5em;
        }
        &::before {
          background-color: $yellow;
        }
      }
    }
    &:nth-child(3) {
      .text-box {
        .button:hover {
          color: $green;
        }
        &::before {
          background-color: $green;
        }
      }
    }

  }

}


.reveal {
  .close-button {
    right: 20px;
    top: 20px;
    .svg-icon {
      width: rem-calc(28);
      height: rem-calc(28);
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .img-text-box {
    .img-box {
      left: 20%;
      width: 60%;
    }
    .text-box {
      margin-left: 0;
      margin-right: 0;
      h3 {
        text-transform: uppercase;
      }
    }
  }
}