.isofix {
  position: relative;
  padding: rem-calc(10);
  @include breakpoint(large) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: -2;
    padding: rem-calc(26 100);
  }
  img {
    width: 1700px;
    height: auto;
    margin: 20px auto;
    max-width: 100%;
    display: block;
    @include breakpoint(large) {
      padding-left: rem-calc(45);
    }
  }
}

#sticky-height {
  @include breakpoint(large) {
    margin-left: 0;
    padding-left: rem-calc(26);
    padding-top: 4em;
    padding-bottom: rem-calc(26);
  }
}