ul:not(.menu):not(.orbit-container) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}



ol.olcircle {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 50px;
  margin-left: 0;
  margin-bottom: 3.5em;
  margin-top: 1em;
  font-size: rem-calc(15);
  @include breakpoint (large) {
    margin-bottom: 5.5em;
    margin: 0 auto;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin: 1.4em 0 0 0;
    counter-increment: my-awesome-counter;
    position: relative;
    margin-bottom: rem-calc(16);
    @include breakpoint (large) {
      margin-right: 70px;
    }
    &::before {
      content: counter(my-awesome-counter);
      color: $white;
      font-weight: bold;
      position: absolute;
      font-size: rem-calc(30);
      left: calc(-1 * 10px - 38px);
      width: 38px;
      height: 38px;
      line-height: 1.2;
      top: -8px;
      background: $yellow;
      border-radius: 50%;
      text-align: center;
    }
  }

}
