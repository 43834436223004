footer {
  background-color: $gray;
  color: $white;
  padding: rem-calc(40 10);
  border: 10px solid $white;
  @include breakpoint(medium) {
    border: 26px solid $white;
    padding: rem-calc(40 26);
  }
  @include breakpoint (large) {
    font-size: rem-calc(15);
  }
  @include breakpoint (1360) {
    font-size: rem-calc(16);
  }
  .footer-logo {
    width: rem-calc(116);
    display: block;
    margin-bottom: rem-calc(30);
    @include breakpoint (medium) {
      width: rem-calc(178);
    }
    @include breakpoint (large) {
      margin-bottom: 0;
      margin-right: rem-calc(10);
    }
  }
  p {
    @include breakpoint (large) {
      margin-bottom: 0;
    }
    @include breakpoint (1400) {
      margin-right: rem-calc(10);
    }
    @include breakpoint (1500) {
      margin-right: rem-calc(30);
    }
  }
  span {
    display: block;
    @include breakpoint (large) {
      display: inline-block;
    }
  }
  address {
    margin-bottom: rem-calc(25);
    @include breakpoint (large) {
      margin-bottom: 0;
    }
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $medium-gray;
      text-decoration: none;
    }
  }
}
