.button:not(.navi-button) {
  border-radius: 0;

  border: 2px solid $white;
  background-color: transparent;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background-color: $white;
    color: $red;
  }

  &.btn-gray {
    border: 2px solid #6B6C6D;
    background-color: transparent;
    color: #6B6C6D;
    text-decoration: none;
    display: inline-block;
    &:hover {
      background-color: #6B6C6D;
      color: $white;
    }
  }
}

.btn-negative {
  border: 2px solid $white;
  background-color: transparent;
  color: $white;
  &:hover {
    background-color: $white;
    color: $red;
  }
}



.circle {
  position: relative;
  border-radius: 50%;
  color: $white;
  font-size: rem-calc(14);
  text-transform: uppercase;
  width: rem-calc(120);
  height: rem-calc(120);
  transition: all .2s ease-in-out;
  @include breakpoint (large) {
    font-size: rem-calc(24);
    width: rem-calc(200);
    height: rem-calc(200);
  }
  &::before{
    content:"";
    visibility: visible;
    position:absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width:100%;
    border-radius: 50%;
    background-color:$red;
    mix-blend-mode:multiply;
    z-index:-1;
  }
  &--big {
    font-size: rem-calc(18);
    @include breakpoint (large) {
      font-size: rem-calc(34);
    }
  }
  .circle-txt {
    text-align: center;
    position: relative;
    line-height: 1.2;
    top: 48%;
    transition: all .2s ease-in-out;
    transform: translateY(-50%) rotate(-7deg);
  }
  &.link {
    &:hover {
      cursor: pointer;
      color: $white;
      .circle-txt {
        transform: translateY(-50%) rotate(0deg);
      }
    }
    &:focus, &:active {
      color: $white;
    }
  }
}


//position btn main img
.main-image, figure {
  .circle {
    position: absolute;
    bottom: rem-calc(30);
    right: rem-calc(20);
    @include breakpoint (medium) {
      bottom: rem-calc(60);
      right: rem-calc(60);
    }
    @include breakpoint (large) {
      bottom: rem-calc(100);
      right: rem-calc(100);
    }
    &--big {
      left: rem-calc(20);
      right: auto;
      @include breakpoint (medium) {
        left: rem-calc(60);
      }
      @include breakpoint (large) {
        left: rem-calc(100);
      }
    }
  }
  .arrow-down {
    position: absolute;
    bottom: rem-calc(20);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 6;
    opacity: 1;
    transition: all .2s ease-in-out;

    .icon {
      //fill: $white;
    }
    &:hover {
      opacity: .6;
    }
  }
}

figure {
  .circle {
    top: rem-calc(20);
    bottom: auto;
    background: $green;
    @include breakpoint (medium) {
      top: rem-calc(60);
    }
    @include breakpoint (large) {
      top: rem-calc(100);
    }
  }
}

.map-2-container {
  position: relative;
  .circle {
    position: absolute;
    bottom: rem-calc(-20);
    right: rem-calc(-10);
    @include breakpoint (medium) {
      bottom: rem-calc(20);
      right: rem-calc(10);
    }
  }
}

.main-image .arrowdown {
  position: absolute;
  bottom: 7vh;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  cursor: pointer;
  .svg-icon {
    width: 34px;
    height: 19px;
  }
}
