.logo {
  position: relative;
  padding: rem-calc(50 35 25 35);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  @include breakpoint (medium) {
    padding: rem-calc(70 60 35 60);
  }
  @include breakpoint (large) {
    padding: rem-calc(96 85 45 85);
  }
  a {
    display: block;
    img {
      width: rem-calc(130);
      height: auto; //immer width und height in PX angeben (IE)
      max-width: none;
      @include breakpoint (medium) {
        width: rem-calc(170);
      }
      @include breakpoint (large) {
        width: rem-calc(266);
      }
    }
  }
}


