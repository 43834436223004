

//global font-size (small, medium, large)
main {
  font-size: rem-calc(16);
  @include breakpoint (medium) {
    //font-size: rem-calc(20);
  }
}

h1, h2 {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}

h1 {
  span {
    display: block;
    font-weight: 600;
    font-size: rem-calc(16);
    margin-top: 10px;
  }
}

h3 {
  font-weight: 600;
  margin-bottom: $paragraph-margin-bottom;
}

p {
  margin-bottom: 1.4em;
}

.link-black-underline {
  a {
    color: $black;
    text-decoration: underline;
    margin-bottom: 1em;
    display: block;
  }
}
