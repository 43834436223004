.hamburger {
  position: fixed;
  right: rem-calc(15);
  top: rem-calc(10);
  z-index: 999;
  @include breakpoint(medium) {
    right: rem-calc(50);
    top: rem-calc(40);
  }
  &.white {
    button span {
      background-color: $white;
    }
  }
  button {
    position: relative;
    width: rem-calc(50);
    height: rem-calc(50);
    display: block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent !important;
    span {
      display: block;
      background-color: $red;
      position: absolute;
      width: rem-calc(40);
      left: rem-calc(6);
      height: rem-calc(3);
      top: rem-calc(15);
      opacity: 1;
      transition: all .3s ease-in-out;
      + span {
        top: rem-calc(23);
        + span {
          top: rem-calc(31);
        }
      }
    }

    &[aria-expanded="true"] {
      span {
        transform: rotate(45deg);
        top: rem-calc(22);
        background-color: $white;
        + span {
          opacity: 0;
          + span {
            opacity: 1;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }


  &:hover {
    cursor: pointer;
  }
}
