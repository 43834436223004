#main-menu {
  font-size: rem-calc(45);
  text-transform: uppercase;
  margin-top: 20vh;
  .menu {
    li {
      padding: rem-calc(5 0);
      a {
        color: $white;
        outline: none;
        padding: 1rem 0 0.5rem 0;
        &:hover {
          color: $yellow;
        }
      }
      &.active {
        a {
          border-bottom: 3px solid $white;
        }
      }
    }
  }
}
