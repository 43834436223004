table {
  margin-bottom: 3.5em;
  @include breakpoint (small only) {
    font-size: rem-calc(15);
  }
  thead {
    color: $white;
  }
  tbody td, thead th {
    padding: rem-calc(4 8);
    &:first-child {
      padding-left: 2px !important;
    }
  }
}


.table.dataTable {
  font-size: rem-calc(15);
  @include breakpoint (small only) {
    font-size: rem-calc(13);
  }
  line-height: 1;
  border-collapse: collapse;
  position: relative;
  margin-bottom: 3em;
  thead {
    color: $black;
    tr {
      border-top: 3px solid $black;
      border-bottom: 1px solid $black;
      th {
        padding: rem-calc(20 5 17 5);
        text-align: left;
        position: sticky;
        top: -3px;
        background: $white;
        font-weight: 600;
        text-transform: uppercase;
        &:first-child {
          width: auto !important;
        }
        span {
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid $black;
      border-top: 2px solid transparent;
      &:focus {
        background-color: $green;
        border-left: 1px solid $green;
        border-right: 1px solid $green;
        outline: none;
        color: $white;
        td .icon {
          fill: $white !important;
        }
      }
      td {
        cursor: pointer;
        padding: rem-calc(13 5 12 5);
        text-align: left;
        white-space: nowrap;
        font-weight: normal;
        @include breakpoint (small only) {
          padding: rem-calc(11 3 9 3);
        }
        .svg-icon {
          margin-top: -0.8em;
          margin-right: 8px;
        }
        .icon-status {
          width: 20px;
          height: 22px;
          font-size: 1rem;
          margin-left: 5px;

        }
        .icon-pdf {
          width: 20px;
          height: 27px;
          //fill: $black;
          @include breakpoint (small only) {
            width: 16px;
            height: 20px;
          }
        }
      }
      td:first-of-type {
        > span {
          display: inline-block;
          vertical-align: middle;
          text-transform: lowercase;
          padding-left: 2px;
        }
      }
      td:last-of-type {
        > a {
          line-height: 1;
          font-size: 0;
          color: var(--object-color);
          display: block;
        }
      }
    }
  }
}

//scroll table horizontal
.dataTables_wrapper {
  //max-height: rem-calc(530);
  overflow: auto;
}

//hide arrows for sorting
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
}

//show border-bottom for sorting
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  span {
    border-bottom: 1px solid $black;
  }
}

.whgimage {
  margin-bottom: rem-calc(20);
  @include breakpoint (large) {
    padding-top: rem-calc(20);
  }
}

#whgnE tbody tr, #whgnE thead tr {
  border-color: $green;
}
#whgnE thead tr th {
  color: $green;
}
#whgnE .icon {
  fill: $green;
}

#whgnF tbody tr, #whgnF thead tr {
  border-color: $red;
}
#whgnF thead tr th {
  color: $red;
}
#whgnF .icon {
  fill: $red;
}

#whgnG tbody tr, #whgnG thead tr {
  border-color: $yellow;
}
#whgnG thead tr th {
  color: $yellow;
}
#whgnG .icon {
  fill: $yellow;
}

#whgnF tbody tr:focus {
  background-color: $red;
  border-left: 1px solid $red;
  border-right: 1px solid $red;
}
#whgnG tbody tr:focus {
  background-color: $yellow;
  border-left: 1px solid $yellow;
  border-right: 1px solid $yellow;
}

.table-head {
  text-transform: uppercase;
  font-weight: 600;
  font-size: rem-calc(20);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  @include breakpoint (medium) {
    font-size: rem-calc(41);
  }
  i {
    font-style: normal;
    order: 0;
    flex: 0 1 auto;
    align-self: flex-end;
    @include breakpoint (medium) {

    }
  }
  span {
    font-size: rem-calc(15);
    text-transform: none;
    font-family: $body-font-family;
    order: 0;
    flex: 0 0 auto;
    align-self: flex-end;
    padding-bottom: 7px;
    @include breakpoint (medium) {
      font-size: rem-calc(18);
    }
  }
  &--e {
    color: $green;
  }
  &--f {
    color: $red;
  }
  &--g {
    color: $yellow;
  }
}

.table-sub {
  padding: 1em 0;
}
