html.sr {
  .fadeinbottominterval, .fadeinbottom {
    visibility: hidden;
  }
}


.c-yellow {
  color: $yellow;
}
.c-green {
  color: $greentxt;
}
.c-blue {
  color: $bluetxt;
}
.c-white {
  color: $white;
}

.bg-white {
  background-color: $white;

  //speziell wegen Isometrien und Blend-Mode Boxen
  z-index: 1;
  position: relative;
}
.bg-green {
  background-color: $green-dark;
}
