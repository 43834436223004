section {
  padding-top: rem-calc(10);
  @include breakpoint (medium) {
    padding-top: rem-calc(26);
  }
  &#kontakt {
    background-color: $red;
    padding: 8em 2em 5em 2em;
    color: $white;
    position: relative;
    z-index: 10;
    margin-top: rem-calc(10);
    @include breakpoint (medium) {
      margin-top: rem-calc(26);
    }
    h2 {
      margin-bottom: 1em;
      + p {
        font-size: 1.85em;
        margin-bottom: 2em;
      }
    }
  }
  &.nopadding {
    padding: 0;
  }
}

section.bg-white + #kontakt {
  margin-top: 0;
}