.combi-figure {
  position: relative;
  display: block;
  margin-bottom: rem-calc(10);
  &.two-columns-left {
    margin-right: rem-calc(5);
  }
  &.two-columns-right {
    margin-left: rem-calc(5);
  }
  img {
    width: 100%;
  }
}

.main-image {
  height: calc(100vh - 10px);
  padding: rem-calc(10 10 0 10);
  @include breakpoint(medium) {
    height: calc(100vh - 26px);
    padding: rem-calc(26 26 0 26);
  }
  picture {
    z-index: -1;
    position: relative;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.small-img-right {
  margin-bottom: rem-calc(15);
  @include breakpoint (medium) {
    position: relative;
    right: -20px;
  }
  @include breakpoint (large) {
    right: -40px;
  }
  @include breakpoint (xlarge) {
    right: -100px;
  }
}

.small-img-left {
  margin-bottom: 2em;
  @include breakpoint (medium) {
    position: relative;
    left: -20px;
  }
  @include breakpoint (large) {
    left: -40px;
  }
  @include breakpoint (xlarge) {
    left: -100px;
  }
}
