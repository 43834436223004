:root {
  .tb-red {
    --tb-bg: #6E3228;
  }
  .tb-green {
    --tb-bg: #286E5F;
  }
  .tb-blue {
    --tb-bg: #0A3C4B;
  }
  .tb-yellow {
    --tb-bg: #EBAA5F;
  }
}

.img-text-box {
  position: relative;
  .img-box {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    aspect-ratio: 0.9;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text-box {
    position: relative;
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
    color: $white;
    text-align: center;
    padding: 7em 2em 2em 2em;
    margin-top: -5.5em;
    h1,h2,p {
      color: $white;
      max-width: 100%;
      width: rem-calc(700);
      margin-left: auto;
      margin-right: auto;
    }
    &.quote h2 {
      margin-bottom: 0;
    }
    &::before{
      content:"";
      visibility: visible;
      position:absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width:100%;
      background-color:var(--tb-bg);
      mix-blend-mode:multiply;
      z-index:-1;
    }
  }
  &.reverse-order {
    .img-box {

    }
    .text-box {

    }
  }


  &.desktop {
    @include breakpoint(medium) {
      padding-top: rem-calc(50);
      padding-bottom: rem-calc(50);
      .text-box {
        margin-left: 30%;
        margin-right: 0;
        margin-top: 0;
        padding: 2em;
      }
      .img-box {
        position: absolute;
        right: 40%;
        aspect-ratio: auto;
      }
    }
    @include breakpoint(large) {
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(100);
      .text-box {
        padding: rem-calc(130 120 130 120);
      }
    }

    &.reverse-order {
      @include breakpoint(medium) {
        .img-box {
          left: 40%;
          right: 0;
        }
        .text-box {
          margin-left: 0;
          margin-right: 30%;
        }
      }
    }
  }

}

.reverse-order-h {
  .text-box {
    margin-top: 0;
  }
}

